<template>
    <div class="flex completion-main">
        <br>
        <h4>Photo (optional)</h4>

        <Photo/>

        <h4>About yourself (optional)</h4>

        <Textarea v-model="nutshell" :autoResize="true" rows="5" cols="30" placeholder="In a nutshell - what can users expect from you? (<100 words)" />
            <br>
        <Textarea v-model="presentation" :autoResize="true" rows="5" cols="30" placeholder="Write something about yourself (<1000 words)." />
            <br>
        <Textarea v-model="education" :autoResize="true" rows="5" cols="30" placeholder="List your education." />
            <br>
        <Textarea v-model="work_experience" :autoResize="true" rows="5" cols="30" placeholder="List your work experience." />
            <br>
        <Textarea v-model="coaching_experience" :autoResize="true" rows="5" cols="30" placeholder="List your coaching experience." />
            <br>

        <h4>Socials (optional)</h4>

        <span class="p-input-icon-left">
            <i class="pi pi-youtube"></i>
            <InputText v-model="ytlink" style="width:100%" type="text" placeholder="Link to your introductory video, e.g. 'https://www.youtube.com/watch?v=GZ1aTwwLS44'" />
        </span>

        <br>
        <div class="ytvideo" v-if="ytlink != '' && ytlink.includes('youtube.com')">
            <iframe width="560" height="340" :src="ytlink.replace('watch?v=', 'embed/')" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <br v-if="ytlink != ''">

        <span class="p-input-icon-left">
            <i class="pi pi-linkedin"></i>
            <InputText v-model="linkedinlink" style="width:100%" type="text" placeholder="Link to your LinkedIn profile" />
        </span>

        <br>
        <span class="p-input-icon-left">
            <i class="pi pi-twitter"></i>
            <InputText v-model="twitterlink" style="width:100%" type="text" placeholder="Link to your Twitter account" />
        </span>

        <br>
        <span class="p-input-icon-left">
            <i class="pi pi-globe"></i>
            <InputText v-model="websitelink" style="width:100%" type="text" placeholder="Link to your website" />
        </span>


            <br>
        <div class="justify-center">
        <Button label="Finish and review" icon="pi pi-check" class="w-8" v-on:click="submit"></Button>
        </div>

    </div>
</template>

<script>
import { WebCam } from "vue-web-cam";
import UserService from '../../service/UserService';
import Photo from '../C_Photo.vue';

export default {
    components: {Photo},
    data() {
        return {
            nutshell: "",
            presentation: "",
            education: "",
            work_experience: "",
            coaching_experience: "",
            ytlink: "",
            linkedinlink: "",
            twitterlink: "",
            websitelink: "",
        }
    },
    userService: null,
    created() {
        this.userService = new UserService();
    },
    methods: {
        submit(event) {
            return this.userService.updateUser(
                {
                    "about": this.presentation,
                    "education": this.education,
                    "work_experience": this.work_experience,
                    "coaching_experience": this.coaching_experience,
                    "coach": true,
                    "ytlink": this.ytlink,
                    "linkedinlink": this.linkedinlink,
                    "twitterlink": this.twitterlink,
                    "websitelink": this.websitelink,
                    "nutshell": this.nutshell,
                }
            ).then(() => this.$router.push("/signupcoach/review"));
        },
    }
}
</script>
<style scoped lang="scss">
.completion-main {
    flex-direction: column;
}
.ytvideo {
    margin: 0 auto;
}
</style>
